<template>
  <registration-form></registration-form>
</template>

<script>
import RegistrationForm from "@/components/RegistrationForm.vue";

export default {
  components: {
    RegistrationForm,
  },
  data() {
    return {};
  },
};
</script>
